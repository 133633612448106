<template>
  <!-- #default="{ errors }" name="category_code" vid="category_code" rules="required|email|max:100" -->
  <ValidationProvider
    v-slot="{ errors }"
    :name="name"
    :vid="idInput"
    :rules="initialRules"
  >
    <b-form-group>
      <label :for="idInput">{{ name }}</label>
      <b-input
        :id="idInput"
        v-model="inputValue"
        :type="inputType"
        :class="[errors.length > 0 ? ' is-invalid' : '']"
        :placeholder="placeholder"
        autocomplete="off"
        :name="idInput"
        @change="changeValue"
      />
      <div
        v-if="errors.length > 0"
        class="invalid-feedback"
      >

        <span>{{ errors[0] }}</span>
      </div>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'Input',
  props: {
    initialName: {
      type: String,
      default: ''
    },
    initialIdInput: {
      type: String,
      default: ''
    },
    initialInputType: {
      type: String,
      default: ''
    },
    initialInputValue: {
      type: String,
      default: ''
    },
    initialRules: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  //   props: ['initialName', 'initialIdInput', 'initialInputType', 'initialInputValue', 'initialRules'],
  data () {
    return {
      name: this.initialName,
      idInput: this.initialIdInput,
      inputValue: this.initialInputValue,
      inputType: this.initialInputType,
      placeholder: 'Enter ' + this.initialName,
      initialrules: this.initialRules
    }
  },

  methods: {
    changeValue (event) {
      this.$emit('changeValue', event, this.idInput)
    }
  }
}
</script>
